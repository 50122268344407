import ButtonGroup from '@mui/material/ButtonGroup';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import React, { useState } from 'react';
import {
  StyledButton,
  Container,
  LayoutImage,
  StyledItem,
  StyledMenuItem,
} from '@/components/ComboBox/styles';
import { imgUrlChk } from '@/utils/function';
import SvgImage from '@/components/Module/SvgImage';

type P = {
  title: string;
  defaultKey: string;
  dataList: {
    key: string;
    profileImageUrl?: string;
    title: string;
    subtitle?: string;
  }[];
  handleMenuItemClick: (key: string) => void;
};
const ComboBox = ({ title, defaultKey, dataList, handleMenuItemClick }: P) => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        style={{ boxShadow: 'none', width: '100%' }}
      >
        <StyledButton
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
        >
          <div style={{ fontSize: '14px' }}>{title}</div>
          <SvgImage
            name={'arrowDown'}
            style={open ? { transform: 'rotate(180deg)' } : {}}
          />
        </StyledButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2,
          width: '100%',
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {dataList &&
                    dataList.map((data) => (
                      <StyledMenuItem
                        key={data.key}
                        disabled={defaultKey === data.key}
                        selected={defaultKey === data.key}
                        onClick={() => handleMenuItemClick(data.key)}
                      >
                        <Container>
                          {data.profileImageUrl && (
                            <LayoutImage
                              width={30}
                              height={30}
                              src={imgUrlChk(data.profileImageUrl)}
                              alt={`passportProfile-${data.key}`}
                            />
                          )}
                          <StyledItem>
                            {data.title}
                            {data.subtitle && <span>{`${data.subtitle}`}</span>}
                          </StyledItem>
                        </Container>
                      </StyledMenuItem>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
export default ComboBox;
