import { Controller, useForm } from 'react-hook-form';
import { RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState } from 'react';
import {
  Container,
  CustomFormLabel,
  CustomRadio,
  SubContainer,
} from '@/components/Profile/SecondItem/Settings/SalesSettings/styles';
import { useRecoilState } from 'recoil';
import { appSettingState } from '@/recoil/atom';
import { AppConfig } from '@/types/api';
import { RnMessage, sendToRN } from '@/utils/function';

const SalesSettings = () => {
  const [state, setState] = useRecoilState(appSettingState);
  const [changeState, setChangeState] = useState<
    Pick<AppConfig, 'dayFind' | 'seasonFind'>
  >({
    dayFind: state.dayFind,
    seasonFind: state.seasonFind,
  });

  const [isChanged, setIsChanged] = useState(false);

  const { control } = useForm<{
    dayFind: 'true' | 'false';
    seasonFind: 'true' | 'false';
  }>({
    values: {
      dayFind: changeState.dayFind ? 'true' : 'false',
      seasonFind: changeState.seasonFind ? 'true' : 'false',
    },
  });

  useEffect(() => {
    if (
      state.dayFind !== changeState.dayFind ||
      state.seasonFind !== changeState.seasonFind
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [changeState]);

  const handleClick = () => {
    if (confirm('변경된 설정을 적용하기 위해 앱을 재기동합니다.')) {
      sendToRN(RnMessage.SET_SALES_DEFAULT_DATA, {
        dayFind: changeState.dayFind,
        seasonFind: changeState.seasonFind,
      });
    }
  };

  return (
    <Container>
      <SubContainer>
        <CustomFormLabel>기본 조회 일자</CustomFormLabel>
        <Controller
          name={'dayFind'}
          control={control}
          render={({ field: { value } }) => (
            <RadioGroup
              onChange={(e) => {
                setChangeState({
                  ...changeState,
                  dayFind: e.target.value === 'true',
                });
              }}
              value={value}
            >
              <FormControlLabel
                value={false}
                control={<CustomRadio />}
                label="어제"
              />
              <FormControlLabel
                value={true}
                control={<CustomRadio />}
                label="오늘"
              />
            </RadioGroup>
          )}
        />
      </SubContainer>
      <SubContainer>
        <CustomFormLabel>기본 조회 시즌</CustomFormLabel>
        <Controller
          name={'seasonFind'}
          control={control}
          render={({ field: { value } }) => (
            <RadioGroup
              onChange={(e) => {
                setChangeState({
                  ...changeState,
                  seasonFind: e.target.value === 'true',
                });
              }}
              value={value}
            >
              <FormControlLabel
                value={true}
                control={<CustomRadio />}
                label="현재 시즌"
              />
              <FormControlLabel
                value={false}
                control={<CustomRadio />}
                label="전체 시즌"
              />
            </RadioGroup>
          )}
        />
      </SubContainer>
      <button
        style={{
          borderRadius: '6px',
          padding: '14px',
          color: 'white',
          backgroundColor: 'black',
          width: '100%',
          opacity: isChanged ? '1' : '0.2',
          fontSize: '14px',
        }}
        disabled={!isChanged}
        onClick={handleClick}
      >
        {'저장'}
      </button>
    </Container>
  );
};

export default SalesSettings;
