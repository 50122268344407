import styled from '@emotion/styled';

const Layout = styled.div`
  padding: 2rem;
  font-family: var(--font-Pretendard);
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const StyledSubTitle = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
`;

const StyledContent = styled.div`
  margin-top: 1rem;
`;

const StyledSubContent = styled.div`
  margin-top: 0.5rem;
  padding: 1rem 1.5rem;
  background-color: rgba(249, 250, 251, 1);
  color: ${({ theme }) => theme.colors.textPrimary};
  border-radius: 0.42rem;
  > ul {
    margin: 0;
    padding-left: 1rem;
    list-style-position: outside;
    text-align: start;
    justify-content: start;
    align-items: start;
  }
`;

const StyledButton = styled.button`
  margin-top: 1rem;
  border-radius: 0.42rem;
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 1rem 1rem;
  color: white;
  width: 100%;
  :disabled {
    opacity: 0.3;
  }
`;

const StyledLinkButton = styled.a`
  margin-top: 3rem;
  border-radius: 0.42rem;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
`;

StyledLinkButton.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
  href: 'https://www.ipayview.com/',
};

const StyledLayout = styled.div`
  display: flex;
  margin-top: 0.5rem;
`;
const StyledMiniTitle = styled.div`
  width: 6rem;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

const StyledSecondSubContent = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-weight: 300;
  font-size: 13px;
`;

export {
  Layout,
  StyledTitle,
  StyledContent,
  StyledSubContent,
  StyledButton,
  StyledLinkButton,
  StyledSubTitle,
  StyledLayout,
  StyledMiniTitle,
  StyledSecondSubContent,
};
