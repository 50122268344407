import React, { ReactNode } from 'react';
import {
  Container,
  StyledBack,
  StyledHeader,
} from '@/components/Profile/SecondItem/Header/styles';
import SvgImage from '@/components/Module/SvgImage';

type P = {
  children: ReactNode;
  onClose: () => void;
  width?: string;
};
const Header = ({ children, onClose, width }: P) => {
  return (
    <Container width={width}>
      <StyledHeader>
        <StyledBack onClick={onClose}>
          <SvgImage name={'back'} />
        </StyledBack>
      </StyledHeader>
      {children}
    </Container>
  );
};

export default Header;
