import styled from '@emotion/styled';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';

const CustomRadio = styled(Radio)`
  color: black !important;
`;

const CustomFormLabel = styled(FormLabel)`
  font-size: 14px;
  font-weight: 500;
  color: black;
`;

const Container = styled.div`
  font-family: var(--font-Pretendard);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: var(--font-Pretendard);
`;

const SubContainer = styled.div`
  margin-bottom: 10px;
`;

export { CustomRadio, CustomFormLabel, Container, SubContainer };
