import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Image from 'next/image';
import { MenuItem } from '@mui/material';
const StyledButton = styled(Button)`
  display: flex;
  background-color: white;
  color: black;
  border: 1px solid #e7e9ec;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: ${(theme) => theme.theme.colors.white};
  }
  height: 50px;
  box-sizing: border-box;
`;
const StyledItem = styled.div`
  > span {
    display: block;
    color: gray;
  }
`;

const LayoutImage = styled(Image)`
  border-radius: 50%;
  object-fit: cover;
  border: 0.5px solid lightgray;
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const StyledMenuItem = styled(MenuItem)`
  padding: 16px;
  margin: 4px 4px;
  :first-child {
    margin-top: 0;
  }
  color: black;
  &.Mui-disabled {
    background: rgba(244, 244, 244, 1);
  }
  &:hover {
    background: rgba(244, 244, 244, 1);
  }
`;

export { StyledButton, StyledItem, LayoutImage, Container, StyledMenuItem };
