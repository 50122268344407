import styled from '@emotion/styled';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2rem;
  font-family: var(--font-Pretendard);
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const StyledContent = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const StyledSubContent = styled.div`
  margin-top: 1rem;
  padding: 1rem 2rem;
  color: #f55060;
  background-color: rgba(245, 80, 96, 12%);
  border-radius: 0.42rem;
  > span {
    text-decoration: underline;
    font-weight: 600;
  }
`;

const StyledButton = styled.button`
  margin-top: 1rem;
  border-radius: 0.42rem;
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 1rem 1rem;
  color: white;
  width: 100%;
  :disabled {
    opacity: 0.3;
  }
`;

const StyledInput = styled.input`
  border-radius: 0.42rem;
  border: none;
  width: 100%;
  outline: none;
`;

const StyledContainer = styled.div`
  margin-top: 1rem;
  padding: 1rem 1rem;
  border: 1px solid gray;
  border-radius: 0.42rem;
`;

export {
  Layout,
  StyledTitle,
  StyledContent,
  StyledSubContent,
  StyledButton,
  StyledInput,
  StyledContainer,
};
