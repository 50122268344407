import SvgImage from '@/components/Module/SvgImage';
import { appVersionState } from '@/recoil/atom';
import { useRecoilState } from 'recoil';

const AppVersionInfo = () => {
  const [versionState, setVersionState] = useRecoilState(appVersionState);

  return (
    <div
      style={{
        display: 'flex',
        justifySelf: 'center',
        flexGrow: '1',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '20px',
        padding: '20px',
        height: 'calc(100vh - 57px)',
      }}
    >
      {versionState?.isNeeded && <SvgImage name={'start'} />}
      <div style={{ fontSize: '14px' }}>
        {versionState?.isNeeded
          ? '새로운 업데이트가 있어요.'
          : '최신 버전을 사용 중이에요.'}
      </div>
      <div style={{ fontSize: '14px', color: '#787878', minWidth: '50px' }}>
        {`버전 ${versionState?.currentVersion}${
          versionState.isTestFlight ? '(TestFlight)' : ''
        }`}
      </div>
      {versionState?.isNeeded && (
        <a
          style={{
            borderRadius: '6px',
            padding: '14px',
            color: 'white',
            backgroundColor: 'black',
            width: '100%',
            textAlign: 'center',
          }}
          href={versionState?.storeUrl}
          target={'_blank'}
          rel={'noreferrer'}
        >{`${versionState?.latestVersion}로 업데이트`}</a>
      )}
    </div>
  );
};
export default AppVersionInfo;
