import React, { Fragment } from 'react';
import { useChangePassport, usePassport } from '@/hooks/apiHooks';
import { useRouter } from 'next/router';
import ComboBox from '@/components/ComboBox';

type P = {
  mobileMode?: boolean;
};

export default function ChangeUserPassport({ mobileMode }: P) {
  const { data } = usePassport();

  const router = useRouter();

  const handleMenuItemClick = (passportId: string) => {
    if (confirm('새로고침하여 계정이 전환됩니다.')) {
      useChangePassport(passportId).then((_) => {
        router.reload();
      });
    }
  };

  return (data?.assignedPassports.length || 0) > 1 ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mobileMode ? '24px 20px' : '',
        width: '100%',
      }}
    >
      <ComboBox
        title={data?.defaultPassport.detail.companyName || ''}
        defaultKey={data?.defaultPassport.passportId || ''}
        dataList={
          data
            ? data?.assignedPassports.map((res) => {
                return {
                  key: res.passportId,
                  profileImageUrl: res.detail.profileImageUrl,
                  title: res.detail.companyName,
                  subtitle: res.detail.departmentName,
                };
              })
            : []
        }
        handleMenuItemClick={handleMenuItemClick}
      />
    </div>
  ) : (
    <Fragment />
  );
}
