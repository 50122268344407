import { ReactNode, useEffect, useState } from 'react';
import SvgImage from '@/components/Module/SvgImage';
import DrawerHeader from '@/components/Profile/SecondItem/Header';
import { SwipeableDrawer } from '@mui/material';
import SalesSettings from '@/components/Profile/SecondItem/Settings/SalesSettings';
import AppVersionInfo from '@/components/Profile/SecondItem/Settings/AppVersionInfo';
import DeveloperMode from '@/components/Profile/SecondItem/Settings/DeveloperMode';
import { useRecoilState, useRecoilValue } from 'recoil';
import { appSettingState, appVersionState, MyInfoState } from '@/recoil/atom';
import { RnMessage, sendToRN } from '@/utils/function';
import { AppConfig, VersionInfo } from '@/types/api';
import { Layout, StyledButton2 } from './styles';

const Settings = () => {
  const [open, setOpen] = useState(false);
  const [node, setNode] = useState<ReactNode>(<SalesSettings />);
  const { departmentCode } = useRecoilValue(MyInfoState);

  const developerMode = departmentCode === 'D180';
  const [state, setState] = useRecoilState(appSettingState);
  const [versionState, setVersionState] = useRecoilState(appVersionState);

  const handleMessageEvent = (event: MessageEvent | Event) => {
    if ('data' in event && event.data) {
      try {
        const parsed = JSON.parse(event?.data);
        switch (parsed.type) {
          case 'APP_CONFIG':
            setState(parsed.data as AppConfig);
            break;
          case 'VERSION_INFO':
            setVersionState(parsed.data as VersionInfo);
            break;
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    document.addEventListener('message', (e) => {
      handleMessageEvent(e);
    });

    sendToRN(RnMessage.GET_APP_CONFIG);
    sendToRN(RnMessage.GET_VERSION_INFO);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
      document.removeEventListener('message', (e) => handleMessageEvent(e));
    };
  }, []);

  const handleOpen = (node: ReactNode) => {
    setNode(node);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    const result = confirm('로그아웃 하시겠어요?');
    if (result) {
      sendToRN(RnMessage.LOG_OUT);
    }
  };

  const handleInitClick = () => {
    if (confirm('변경된 설정을 적용하기 위해 앱을 재기동합니다.')) {
      sendToRN(RnMessage.SET_SALES_INITIALS);
    }
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <Layout>
      <StyledButton2 onClick={() => handleOpen(<SalesSettings />)}>
        Sales 설정
        <SvgImage name={'right_arrow'} height={24} width={24} />
      </StyledButton2>
      <StyledButton2 onClick={() => handleOpen(<AppVersionInfo />)}>
        {`앱 버전 정보`}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ color: '#787878', fontSize: '12px' }}>{`${
            versionState?.currentVersion
          }${versionState.isTestFlight ? '(TestFlight)' : ''}`}</div>
          <SvgImage name={'right_arrow'} height={24} width={24} />
        </div>
      </StyledButton2>
      {developerMode && (
        <StyledButton2 onClick={() => handleOpen(<DeveloperMode />)}>
          개발모드 전환
          <SvgImage name={'right_arrow'} height={24} width={24} />
        </StyledButton2>
      )}
      {/*<StyledButton2 onClick={handleInitClick}>*/}
      {/*  앱 설정 초기화*/}
      {/*  <SvgImage name={'right_arrow'} height={24} width={24} />*/}
      {/*</StyledButton2>*/}
      <StyledButton2 style={{ height: '52px' }} onClick={handleLogout}>
        로그아웃
      </StyledButton2>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={true}
        ModalProps={{
          keepMounted: false,
        }}
        transitionDuration={500}
      >
        <DrawerHeader onClose={handleClose} width={'100vw'}>
          {node}
        </DrawerHeader>
      </SwipeableDrawer>
    </Layout>
  );
};
export default Settings;
