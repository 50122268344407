import styled from '@emotion/styled';
const Input = styled.input`
  border-style: solid;
  border-color: var(--text-secondary-color);
  height: 2.5rem;
  width: 50%;
  padding: 0.75rem;
  transition: border-color 0.1s ease-in-out;
  border-radius: 0.25rem;
  outline: none;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  :read-only {
    border-color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.secondary};
    color: var(--text-secondary-color);
  }
`;

const StyledPrintButton = styled.button`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  transition: opacity 0.2s ease-in-out;
  :disabled {
    opacity: 0.1;
  }
`;
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const InputLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  gap: 0.2rem;
`;
export { Input, StyledPrintButton, Layout, InputLayout };
