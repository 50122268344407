import styled from '@emotion/styled';

const Container = styled.div`
  margin-top: 1rem;
  border-radius: 0.42rem;
  width: 100%;
  background-color: #f9fafb;
  padding: 20px;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  font-family: var(--font-Pretendard);
`;

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

const StyledContent = styled.div<{ spread: boolean }>`
  font-size: 14px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${({ spread }) => (spread ? 'box' : '-webkit-box')};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const EmptyLayout = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 12px;
`;

const StyledSubTitle = styled.div`
  font-weight: 300;
  margin-bottom: 0.5rem;
  border: 1px solid #e7e9ec;
  border-radius: 4px;
  background-color: rgba(231, 233, 236, 0.32);
  width: fit-content;
  padding: 2px 8px;
`;

const StyledButton = styled.button`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.textSecondary};
  text-decoration: underline;
  font-size: 12px;
  font-weight: 300;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0.5rem;
`;

export {
  Container,
  HeaderLayout,
  StyledTitle,
  StyledContent,
  EmptyLayout,
  StyledSubTitle,
  StyledButton,
};
