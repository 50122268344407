import {
  Container,
  CustomRadio,
} from '@/components/Profile/SecondItem/Settings/SalesSettings/styles';
import { Controller, useForm } from 'react-hook-form';
import { RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState } from 'react';
import { AppConfig } from '@/types/api';
import { useRecoilState } from 'recoil';
import { appSettingState } from '@/recoil/atom';
import { RnMessage, sendToRN } from '@/utils/function';

const DeveloperMode = () => {
  const [state, setState] = useRecoilState(appSettingState);

  const [changeState, setChangeState] = useState<Pick<AppConfig, 'server'>>({
    server: state.server,
  });

  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (state.server !== changeState.server) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [changeState]);

  const { control } = useForm<Pick<AppConfig, 'server'>>({
    values: {
      server: changeState.server,
    },
  });

  const handleClick = () => {
    if (confirm('변경된 설정을 적용하기 위해 앱을 재기동합니다.')) {
      sendToRN(RnMessage.CHANGE_DEVELOPER_MODE, { server: changeState.server });
    }
  };

  return (
    <Container>
      <div>
        <Controller
          name={'server'}
          control={control}
          render={({ field: { value } }) => (
            <RadioGroup
              onChange={(e) =>
                setChangeState({ ...state, server: e.target.value })
              }
              value={value}
            >
              <FormControlLabel
                value={'product'}
                control={<CustomRadio />}
                label="Production"
              />
              <FormControlLabel
                value={'stage'}
                control={<CustomRadio />}
                label="Stage"
              />
              <FormControlLabel
                value={'dev'}
                control={<CustomRadio />}
                label="Development"
              />
            </RadioGroup>
          )}
        />
      </div>
      <button
        style={{
          borderRadius: '6px',
          padding: '14px',
          color: 'white',
          backgroundColor: 'black',
          width: '100%',
          opacity: isChanged ? '1' : '0.2',
        }}
        disabled={!isChanged}
        onClick={handleClick}
      >
        {'저장'}
      </button>
    </Container>
  );
};

export default DeveloperMode;
