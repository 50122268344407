import styled from '@emotion/styled';
import { Input } from '@mui/material';

const StyledButton = styled.button`
  color: #06c;
  display: flex;
  align-items: center;
  gap: 2px;
`;
StyledButton.defaultProps = {
  type: 'button',
};

const StyledLink = styled.button`
  color: #06c;
  display: flex;
  align-items: center;
  gap: 14px;
  > svg {
    vertical-align: middle;
  }
`;
const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  margin-bottom: 20px;
`;

const StyledContainer = styled.div`
  margin-top: 8px;
  padding: 20px 12px;
  background-color: #f3f5f6;
  color: #888d96;
  border-radius: 8px;
`;

const StyledInput = styled(Input)`
  width: 252px;
  border-radius: 0.42rem;
  padding: 0 1rem;
  border: 1px solid black;
  font-size: 16px;
  height: 44px;
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const StyledInputLayout = styled.div<{ open: boolean }>`
  background-color: white;
  position: absolute;
  width: 100%;
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: visibility 0.3s linear, opacity 0.3s linear;
  #submit-button {
    position: absolute;
  }
`;

const StyledIconButton = styled.button`
  padding: 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;

StyledIconButton.defaultProps = {
  type: 'button',
};

const StyledSubmit = styled.button`
  padding: 12px 16px;
  background-color: black;
  color: white;
  width: 100px;
  border-radius: 4px;
`;

const StyledCancel = styled.button`
  padding: 12px 16px;
  background-color: white;
  width: 100px;
  border-radius: 4px;
  border: 1px solid #e7e9ec;
`;

StyledCancel.defaultProps = {
  type: 'button',
};
export {
  Layout,
  StyledButton,
  StyledContainer,
  StyledLink,
  StyledInput,
  StyledForm,
  StyledInputLayout,
  StyledIconButton,
  StyledSubmit,
  StyledCancel,
};
