import {
  Container,
  HeaderLayout,
  StyledTitle,
  StyledContent,
  EmptyLayout,
  StyledSubTitle,
  StyledButton,
} from '@/components/Profile/IntroduceJob/styles';
import { StyledIconButton } from '@/components/Profile/SecondItem/MyInfo/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-duotone-svg-icons';
import React, { useState } from 'react';
import { useFindPassport } from '@/hooks/apiHooks';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';

import JobDialog from '@/components/Profile/IntroduceJob/JobDialog';
import { Skeleton } from '@mui/material';
import SvgImage from '@/components/Module/SvgImage';

type P = {
  fullScreen?: boolean;
};

const IntroduceJob = ({ fullScreen }: P) => {
  const { passportId } = useRecoilValue(MyInfoState);
  const { data, mutate } = useFindPassport(passportId);

  const [open, setOpen] = useState(false);
  const [spread, setSprerad] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const lineNum = data?.jobDescription?.content
    ? data?.jobDescription?.content.split(`\n`).length
    : 0;

  return (
    <Container>
      {data ? (
        <div>
          <HeaderLayout>
            <div style={{ fontSize: '14px' }}>업무소개</div>
            <StyledIconButton onClick={onOpen}>
              <SvgImage name={'write'} height={20} width={20} />
            </StyledIconButton>
          </HeaderLayout>
          <StyledSubTitle>{data?.job}</StyledSubTitle>
          {data?.jobDescription.content || data?.jobDescription.summary ? (
            <>
              <StyledTitle>{data?.jobDescription.summary}</StyledTitle>
              <StyledContent spread={spread}>
                {data?.jobDescription.content}
              </StyledContent>
              {lineNum > 2 && (
                <StyledButton onClick={() => setSprerad(!spread)}>
                  {spread ? '접기' : '펼쳐보기'}
                  <FontAwesomeIcon
                    icon={spread ? faChevronUp : faChevronDown}
                    size="sm"
                  />
                </StyledButton>
              )}
            </>
          ) : (
            <EmptyLayout>
              업무소개를 작성해 임직원분들께 내가 하는 일을 알려주세요.
            </EmptyLayout>
          )}
          <JobDialog
            open={open}
            handleClose={onClose}
            defaultValues={data}
            onSendApi={mutate}
            fullScreen={fullScreen}
          />
        </div>
      ) : (
        <>
          <Skeleton height={'1rem'} width={'50%'} />
          <Skeleton height={'1rem'} />
          <Skeleton height={'1rem'} />
        </>
      )}
    </Container>
  );
};

export default IntroduceJob;
